<template>
    <div class="func-search-bar">
        <div v-if="$v.$error" class="form-error">
            proszę uzupełnić dane
        </div>
        <div class="func-search-bar__content">
            <div class="search-row">
                <div class="search-row__icon">
                    <img src="~@/assets/icons/doctor-ico-white.svg" />
                </div>
                <div class="search-row__content" :class="{'search-row__content--error': $v.selectedDoctor.$error}">
                    <p class="row-label">Specjalista / lekarz</p>
                    <vselect
                    placeholder="Wybierz specjalizację / lekarza"
                    :promotedItems="specialitiesComp"
                    :items="doctorsComp"
                    :value="getDoctorSelectValue"
                    :searchable="true"
                    @live="search.doctors = $event; search.specialities = $event;"
                    @input="selectDoctor($event)">

                        <template #display="{item}">
                            <span v-if="item.specialties">{{item.first_name}} {{item.last_name}}</span>
                            <span v-else>{{item.name}}</span>
                        </template>

                        <template #promotedTitle>Specjalizacja</template>

                        <template #promotedItem="{item}">
                            {{item.name}}
                        </template>

                        <template #itemsTitle>Lekarz</template>

                        <template #item="{item}">
                            {{item.first_name}} {{item.last_name}}<br> <span>{{item.specialties && item.specialties.length ?
                            `(${item.specialties.join(', ')})` : `(${item.prof_code})`}}</span>
                        </template>

                        <template #noItems>
                            Brak specjalizacji / lekarzy do wyboru
                        </template>

                    </vselect>
                    <small v-if="!$v.selectedDoctor.isValid">To pole jest wymagane.</small>
                </div>
            </div>
            <div class="search-row" :class="{'search-row--disabled': visitType !== 'stationary'}">
                <div class="search-row__icon">
                    <img src="~@/assets/icons/city-ico-white.svg" />
                </div>
                <div class="search-row__content" :class="{'search-row__content--error': $v.selectedCity.$error}">
                    <p class="row-label">Miasto</p>
                    <vselect
                    :promotedItems="cities.length > 1 ? [{ name: 'Dowolne' }] : []"
                    :items="citiesComp"
                    placeholder="Wybierz miasto"
                    label="name"
                    :searchable="true"
                    @live="search.cities = $event;"
                    v-model="selectedCity">
                        <template #noItems>
                            Brak miast do wyboru
                        </template>
                    </vselect>
                    <small v-if="!$v.selectedCity.isValid">To pole jest wymagane.</small>
                </div>
            </div>
            <div class="search-row">
                <div class="search-row__icon">
                    <img src="~@/assets/icons/calendar-ico-white.svg" />
                </div>
                <div class="search-row__content" :class="{'search-row__content--error': $v.visitDate.$error}">
                    <p class="row-label">Data</p>
                    <datepicker
                    placeholder="Wybierz datę"
                    :clearable="false"
                    :days="$config.FORMATS.DAY_NAMES"
                    :months="$config.FORMATS.MONTH_NAMES"
                    :disableBefore="`${new Date()}`"
                    displayFormat="dd.mm.yyyy"
                    outputFormat="yyyy-mm-dd"
                    v-model="visitDate"/>
                    <small v-if="!$v.visitDate.required">To pole jest wymagane.</small>
                </div>
            </div>
            <btn type="search" @click="emitData()">szukaj</btn>
        </div>
    </div>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import dateFormat from 'dateformat';
import vselect from '@/components/ui/vselect/vselect.vue';
import datepicker from '@/components/ui/datepicker/datepicker.vue';
import debounce from 'lodash.debounce';

export default {

    name: 'searchBar',
    components: {

        vselect,
        datepicker,

    },
    mixins: [validationMixin],
    props: {

        saved: {
            type: Object,
            default: () => {},
            required: true,
        },

        facility: {
            type: Object,
            default: () => {},
            required: true,
        },

        savedType: {
            type: String,
            default: 'evisit',
        },

    },
    data(){
        return {

            cities: [],
            doctors: [],
            specialities: [],

            visitType: 'evisit',
            selectedDoctor: null,
            selectedSpeciality: null,
            selectedCity: null,
            visitDate: null,
            visitKind: false,

            search: {
                cities: '',
                doctors: '',
                specialities: '',
            },

        };
    },
    validations: {
        visitDate: { required },
        selectedDoctor: {
            isValid() {
                if (this.selectedDoctor || this.selectedSpeciality) return true;
                return false;
            },
        },
        selectedCity: {
            isValid() {

                if (this.visitType !== 'evisit'){
                    if (this.selectedCity) return true;
                    return false;
                }

                return true;
            },
        },
    },
    watch: {

        savedType(){
            this.visitType = this.savedType;
        },

        saved: {
            handler: function(val, oldVal) { // eslint-disable-line
                this.setData();
            },
            deep: true,
        },

    },
    methods: {

        async init(){

            const loader = this.app__setLoader();
            this.setData();
            this.app__loaderDequeue(loader);

        },

        setData(){

            if (this.savedType) this.visitType = this.savedType;
            if (!Object.keys(this.saved).length) return;

            this.cities = this.saved.cities || [];
            this.doctors = this.saved.doctors || [];
            this.specialities = this.saved.specialities || [];

            this.selectedDoctor = this.saved.selectedDoctor || null;
            this.selectedCity = this.saved.selectedCity || null;
            this.selectedSpeciality = this.saved.selectedSpeciality || null;
            this.visitDate = this.saved.visitDate || dateFormat(new Date(), 'yyyy-mm-dd');

            if (this.checkAllFilters()) this.emitData();

        },

        checkAllFilters(){

            let all = false;
            this.$v.$touch();

            if (this.$v.$invalid){
                all = false;
            } else {
                all = true;
            }

            this.$v.$reset();
            return all;

        },

        searching: debounce(function(type, query) { // eslint-disable-line
            if (['person', 'specialty'].includes(type)) {
                const promises = [this.getSugestions('person', query), this.getSugestions('specialty', query)];

                Promise.all(promises).then(data => {
                    this.doctors = [...data[0]];
                    this.specialities = [...data[1]];
                });
                return;
            }

            if (['city'].includes(type)) {
                const promises = [this.getSugestions('city', query)];

                Promise.all(promises).then(data => {
                    this.cities = [...data[0]];
                });
                return;
            }

            this.getSugestions(type, query).then(data => { this[type] = data; });
        }, 500),

        getSugestions(type, query){

            return new Promise((resolve, reject) => {

                this.app__getToken().then(token => {

                    this.$http.get('visits/search_autosuggest/', {
                        headers: {
                            'Content-Type': 'Application/json',
                        },
                        params: {
                            selected_facility_token: token.accessToken,
                            remote_app: token.remoteApp,
                            search_type: type,
                            q: query,
                        },
                    }).then(response => {

                        resolve(response.data);

                    }).catch(error => {
                        this.app__handleHttpFailureResponse(error);
                        reject(new Error(error));
                    });

                });

            });

        },

        selectDoctor(data){

            this.selectedDoctor = null;
            this.selectedSpeciality = null;

            if (data.specialties) this.selectedDoctor = data;
            if (data.name) this.selectedSpeciality = data;

        },

        showTab(tab){
            const types = this.facility.patient_visit_performances_options;
            return types.find(ob => ob.label === tab);
        },

        emitData(){

            this.$v.$touch();
            if (this.$v.$invalid) return;

            const data = {
                doctor: this.selectedDoctor,
                speciality: this.selectedSpeciality,
                city: this.selectedCity,
                visitDate: this.visitDate,
            };

            this.$emit('search', data);

        },

    },
    computed: {

        getDoctorSelectValue(){
            if (this.selectedDoctor) return this.selectedDoctor;
            if (this.selectedSpeciality) return this.selectedSpeciality;
            return '';
        },

        doctorsComp(){

            if (!this.search.doctors) return this.doctors;

            const results = this.doctors.filter(item => {

                const doctor = `${item.first_name} ${item.last_name}`.toLowerCase();
                return doctor.includes(this.search.doctors.toLowerCase());

            });

            return results;

        },

        specialitiesComp(){

            if (!this.search.specialities) return this.specialities;

            const results = this.specialities.filter(item => {

                return item?.name.toLowerCase().includes(this.search.specialities.toLowerCase());

            });

            return results;

        },

        citiesComp(){

            if (!this.search.cities) return this.cities;

            const results = this.cities.filter(item => {

                return item?.name.toLowerCase().includes(this.search.cities.toLowerCase());

            });

            return results;

        },

    },
    created(){
        this.init();
    },

};

</script>

<style lang="scss" src="./searchBar.scss" />
