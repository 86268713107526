<template>
    <div class="ui-vmap" :class="{'not-loaded': !map}">
        <div :id="rand" class="ui-vmap__map"></div>
        <div class="ui-vmap__body">
            <div v-if="department" class="facility">
                <div class="wrapper">
                    <h3>{{department.facility_name}}</h3>
                    <div class="facility__main-data facility__main-data--departament">{{ department.name }}</div>
                    <div class="facility__main-data">{{ department.address }}</div>
                </div>
                <div class="wrapper">
                    <div v-if="department.telephone" class="facility__contact telephone">{{ department.telephone }}</div>
                    <div v-if="department.email" class="facility__contact email">{{ department.email }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import * as L from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

export default {
    
    name: 'vmap',
    props: {

        startZoom: {
            type: Number,
            default: 16,
        },

        mapStart: {
            type: Array,
            default: () => [52.240273, 20.9969565],
        },

        markers: {
            type: Array,
            default: () => [],
        },

        goTo: {
            type: Array,
            default: () => [],
        },

        goToAddress: {
            type: Object,
            default: () => {},
        },

        department: {
            type: Object,
            default: () => {},
        },

    },
    data(){
        return {
            
            rand: null,
            map: null,
            provider: null,

            mapTailer: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            settings: {
                attribution: `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, 
                <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>`,
                maxZoom: 19,
            },

            search: '',
            searchItems: [],
            searchMarker: null,

            mapMarkers: [],
            pointerLocation: [],

            currentLocation: null,

            placeIcon: L.icon({
                iconUrl: require('@/assets/icons/pinezka.png'), // eslint-disable-line
                iconSize: [47, 67],
                iconAnchor: [23, 55],
            }),

        };
    },
    watch: {

        goTo(){
            if (!this.map) return;
            if (this.goTo.length) this.goToLocation(this.goTo[0], this.goTo[1]);
        },

        goToAddress(){
            this.searchByAddress();
        },

        currentLocation(){
            if (this.searchMarker && !this.currentLocation) this.map.removeLayer(this.searchMarker);
        },

    },
    methods: {

        async init(){

            this.map = L.map(this.rand);
            L.tileLayer(this.mapTailer, this.settings).addTo(this.map);

            await this.$nextTick();
            this.setupMarkers();

            this.provider = new OpenStreetMapProvider();

            if (!this.goToAddress) this.map.setView(this.mapStart, this.startZoom);
            if (this.goToAddress) this.searchByAddress();

        },

        generateRand(length){

            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;

            for (let i = 0; i < length; i += 1) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            
            return result;

        },

        async searchProvider(){

            this.searchItems = await this.provider.search({ query: this.search });

        },

        searchByAddress(){

            if (!this.map) return;
            if (this.goToAddress){
                this.provider.search({ query: this.goToAddress }).then(result => {
                    if (result.length) {
                        this.goToLocation(result[0].x, result[0].y);
                        return;
                    }
                    this.goToLocation(this.mapStart[0], this.mapStart[1], true);
                });
            }

        },

        goToLocation(x, y, withoutMarker){

            if (!x || !y) return;

            this.map.setView([y, x], this.startZoom);
            this.pointerLocation = [];

            if (this.searchMarker) this.map.removeLayer(this.searchMarker);
            if (!withoutMarker) this.searchMarker = L.marker([y, x], { icon: this.placeIcon }).addTo(this.map);

        },

        setupMarkers(){

            this.mapMarkers = [];

            this.markers.forEach(marker => {

                const item = L.marker([marker.x, marker.y], { icon: this.violetIcon });
                item.bindPopup(`<h2>${marker.customLabel}</h2>`);
                item.addTo(this.map);
                this.mapMarkers.push(item);

            });

        },

    },
    mounted(){
        this.init();
    },
    created(){
        this.rand = this.generateRand(10);
    },

};

</script>

<style lang="scss" src="./vmap.scss" />
