<template>
    <div class="view-visits">
        <viewWrapper>
            <template #header>
                <tabSwitch v-if="showTypes" :tabs="typeTabs" :value="visitType" @input="switchSearch($event.value); setVisitType($event.value)"/>
                <tabSwitch v-if="showKinds" :tabs="kindsTabs" :value="visitKind" @input="switchSearch($event.value); setVisitKind($event.value)"/>
            </template>
            <template #content>
                <div class="visits">
                    <div class="visits__header">
                        <searchBar
                        v-if="facility"
                        :facility="facility"
                        :savedType="visitType"
                        :saved="getSavedData"
                        @search="saveSearch($event)"/>
                    </div>
                    <div v-if="freeTerms.length" class="visits__content">
                        <div v-for="(item, index) in freeTerms" :key="index" class="facility-item">
                            <visitCalendar
                            :item="item"
                            :visitType="visitType"
                            :visitKind="item.visit_kind"
                            :initialSearchDate="visitDate"
                            @select="selectTerm($event)"/>
                        </div>
                    </div>
                    <div v-if="!freeTerms.length" class="visits__content">
                        <div class="no-terms">
                            <img src="~@/assets/icons/no-terms.svg" />
                            <h3>Brak wolnych terminów</h3>
                            <p>Proszę zmienić kryteria wyszukiwania</p>
                        </div>
                    </div>
                </div>
            </template>
        </viewWrapper>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import findTerms from '@/mixins/local/findTerms';

import viewWrapper from '@/components/layout/viewWrapper/viewWrapper.vue';
import tabSwitch from '@/components/ui/tabSwitch/tabSwitch.vue';
import searchBar from '@/components/functional/searchBar/searchBar.vue';
import visitCalendar from '@/components/functional/visitCalendar/visitCalendar.vue';

export default {

    name: 'Visits',
    mixins: [findTerms],
    components: {
        viewWrapper,
        tabSwitch,
        searchBar,
        visitCalendar,
    },
    data(){
        return {

            typeTabs: [
                {
                    label: 'Stacjonarna',
                    value: 'stationary',
                },
                {
                    label: 'e-wizyta',
                    value: 'evisit',
                },
            ],
            kindsTabs: [
                {
                    label: 'Prywatna',
                    value: 'Prywatna',
                },
                {
                    label: 'NFZ',
                    value: 'NFZ',
                },
            ],

        };
    },
    methods: {

        ...mapActions('global', [
            'setCity',
            'setDoctor',
            'setSpeciality',
            'setVisitDate',
            'setVisitType',
            'setVisitKind',
            'setSelectedTerm',
        ]),

        saveSearch(data){

            this.setCity(data.city);
            this.setDoctor(data.doctor);
            this.setSpeciality(data.speciality);
            this.setVisitDate(data.visitDate);

            this.app__findFreeTerms();

        },

        switchSearch(value){

            if (value === this.visitType || value === this.visitKind) return;

            if ((!this.selectedDoctor && !this.selectedSpeciality) || !this.visitDate) return;
            this.app__findFreeTerms();

        },

        selectTerm(data){
            this.setSelectedTerm(data).then(() => this.manageRoute());
        },

        manageRoute(){

            if (this.isSelectedTermPrivate()) return this.$router.push('/services');
            this.$router.push('/informations');
            return false;

        },

        isSelectedTermPrivate() {
            return this.selectedTerm.newVisit.visit_kind === 'Prywatna';
        },

        isPaymentRequired() {

            return this.selectedTerm.prePayment;

        },

    },
    computed: {
        ...mapState('global', [
            'cities',
            'doctors',
            'specialities',
            'selectedCity',
            'selectedDoctor',
            'selectedSpeciality',
            'selectedTerm',
            'visitDate',
            'visitType',
            'visitKind',
            'freeTerms',
        ]),
        ...mapState('user', ['user', 'facility']),

        showTypes(){
            return this.facility?.patient_visit_performances_options.length > 1;
        },

        showKinds(){
            return this.facility?.patient_visit_kinds_options.length > 1;
        },

        getSavedData(){

            return {
                cities: this.cities,
                doctors: this.doctors,
                specialities: this.specialities,
                selectedCity: this.selectedCity,
                selectedDoctor: this.selectedDoctor,
                selectedSpeciality: this.selectedSpeciality,
                visitDate: this.visitDate,
            };

        },
    },

};

</script>

<style lang="scss" src="./Visits.scss" />
