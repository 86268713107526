<template>
    <div class="app-view-wrapper">
        <div v-if="$slots.header" class="app-view-wrapper__header">
            <slot name="header"></slot>
        </div>
        <div v-if="$slots.content" class="app-view-wrapper__content">
            <slot name="content"></slot>
        </div>
        <div v-if="$slots.footer" class="app-view-wrapper__footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>

export default {
    
    name: 'viewWrapper',

};

</script>

<style lang="scss" src="./viewWrapper.scss" />
