<template>
    <div class="ui-tab-switch">
        <div class="tab-slider" :slider-selector="rand"></div>
        <div v-for="(tab, index) in tabs" :key="index"
        :button-selector="`${rand}-${index}`"
        class="tab-item"
        :class="{'tab-item--active': isActive(index)}"
        @click="setActiveTab(index)">
            <p>{{tab.label}}</p>
        </div>
    </div>
</template>

<script>

export default {
    
    name: 'tabSwitch',
    props: {

        value:{
            type: String,
            default: '',
        },

        tabs:{
            type: Array,
            required: true,
        },

    },
    data(){
        return {

            activeTab: 0,
            rand: null,
            sliderDOM: null,

        };
    },
    watch:{

        value(){
            this.findActiveTab();
        },

    },
    methods: {

        init(){

            this.rand = this.generateRand(5);

            this.$nextTick().then(() => {
                this.sliderDOM = document.querySelector(`[slider-selector="${this.rand}"]`);
                this.findActiveTab();
            });

        },

        findActiveTab(){

            const tabIndex = this.tabs.findIndex(tab => tab.value === this.value);

            if (tabIndex > -1){
                this.setActiveTab(tabIndex);
                return;
            }

            this.setActiveTab(0);

        },

        setActiveTab(index){
            this.activeTab = index;
            this.emitValue(index);
            this.$nextTick().then(() => this.setSliderPossition(index));
        },

        setSliderPossition(index){

            const button = document.querySelector(`[button-selector="${this.rand}-${index}"]`);
            if (!button) return;

            const btnWidth = button.offsetWidth;
            const btnLeft = button.offsetLeft;

            this.sliderDOM.style.width = `${btnWidth}px`;
            this.sliderDOM.style.left = `${btnLeft}px`;

        },

        isActive(index){
            return this.activeTab === index;
        },

        generateRand(length){

            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;

            for (let i = 0; i < length; i += 1) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            
            return result;

        },

        emitValue(index){
            this.$emit('input', this.tabs[index]);
        },

    },
    created(){
        this.init();
    },

};

</script>

<style lang="scss" src="./tabSwitch.scss" />
