<template>
    <div class="func-visit-calendar">

        <div class="func-visit-calendar__content">

            <div class="item-header">
                <div class="doctor">
                    <div class="doctor__img">
                        <img v-if="item.doctor.image" :src="item.doctor.image" alt='' />
                        <img v-else src="~@/assets/icons/doc_blue.svg" />
                    </div>
                    <div class="doctor__content">
                        <h2>{{item.doctor.first_name}} {{item.doctor.last_name}}</h2>
                        <div class="departament">{{item.department.name}}</div>
                        <div v-if="item.doctor.specialties.length" class="specialities">
                            <span v-for="(speciality, index) in item.doctor.specialties"
                            :key="index"
                            :style="{'font-weight: bold;': speciality === specialty}">{{speciality}}</span>
                        </div>
                        <div v-else class="specialities">
                            <span>{{item.doctor.prof_code}}</span>
                        </div>
                    </div>
                </div>
                <div class="dates">
                    <button class="dates__btn dates__btn--prev"
                    v-if="prevWeekFirstDay"
                    @click="findNextFreeTerms(prevWeekFirstDay)"></button>
                    <div class="dates__terms">
                        <div class="term-item" v-for="(term, index) in visitDates" :key="index">
                            <span>
                                {{ term.format('dd DD.MM.YY') }}
                            </span>
                            <span class="visit-counter" v-if="getFreeTermByDate(term).length">
                                ({{ getFreeTermByDate(term).length }})
                            </span>
                        </div>
                    </div>
                    <button class="dates__btn dates__btn--next"
                    @click="findNextFreeTerms(nextWeekFirstDay)"></button>
                </div>
            </div>

            <div class="item-hours">
                <div v-if="termsBlock.free_terms.length" class="item-hours__terms">
                    <div class="term-item" v-for="(term, index) in visitDates" :key="index">
                        <div class="term-item__row">
                            <div v-if="!getFreeTermByDate(term).length" class="hour-item">
                                <p>Brak wizyt</p>
                            </div>
                            <div v-else class="hour-item">
                                <button v-for="(newVisit, index) in getFreeTermByDate(term).slice(0,5)"
                                :key="index"
                                class="hour-item__button"
                                @click.stop="chooseTerm(newVisit)">
                                    {{ newVisit.timeFrom }}
                                </button>
                                <slide-up-down :active="showMore" :duration="500" class="hour-item-slidown">
                                    <button v-for="(newVisit, index) in getFreeTermByDate(term).slice(5, getFreeTermByDate(term).length)"
                                    :key="index"
                                    class="hour-item__button"
                                    @click.stop="chooseTerm(newVisit)">
                                        {{ newVisit.timeFrom }}
                                    </button>
                                </slide-up-down>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!termsBlock.free_terms.length" class="no-terms">
                    <div v-if="termsBlock.first_free_term" class="no-terms__content">
                        <p>Najbliższy wolny termin to {{$moment(termsBlock.first_free_term.date).format('D MMMM YYYY')}}</p>
                        <span
                            @click="findNextFreeTerms(termsBlock.first_free_term.date)">
                            Zobacz wolne terminy >
                        </span>
                    </div>
                    <div v-else-if="termsBlock.hasOwnProperty('first_free_term')" class="no-terms__content">
                        <p>Brak wolnych terminów w ciągu najbliższych {{this.$config.RESTRICTIONS.FIRST_FREE_TERM_FOR_DOCTOR_RANGE}} dni</p>
                        <span
                            @click="findNextFreeTerms(nextSearchRangeDate)">
                            Szukaj dalej >
                        </span>
                    </div>
                    <div v-else class="no-terms__content">
                        Brak wolnych terminów
                    </div>
                </div>
            </div>

            <div v-if="btnShowed" class="item-buttons">
                <label @click="showMore = !showMore" :class="{'active': showMore}">{{showMore ? 'Pokaż mniej': 'Wczytaj kolejne terminy'}}</label>
            </div>

        </div>

        <div v-if="!this.app__isMobile()"
            class="func-visit-calendar__side"
        >
            <div
                v-if="visitType === 'evisit'"
                class="evisit"
            >
                <div class="evisit__body">
                <div class="evisit__img">
                    <img
                    src="~@/assets/images/images/ewizyta.png"
                    alt="ewizyta"
                    >
                </div>
                <h3 class="evisit__content">
                    E-wizyta
                </h3>
                </div>
            </div>
            <vmap
            v-else
            :goToAddress="getSearchAddress(item.department)"
            :department="item.department"/>
        </div>

    </div>
</template>

<script>

import { mapState } from 'vuex';

import SlideUpDown from 'vue-slide-up-down';
import vmap from '@/components/ui/vmap/vmap.vue';

export default {

    name: 'visitCalendar',
    components: {
        vmap,
        SlideUpDown,
    },
    props: {

        item: {
            type: Object,
            default: () => {},
            required: true,
        },

        visitType: {
            type: String,
            default: '',
        },

        visitKind: {
            type: String,
            default: '',
        },

        initialSearchDate: {
            type: String,
            required: true,
        },

        specialty: {
            type: String,
            default: '',
        },

    },
    data(){
        return {

            searchDate: null,
            showMore: false,
            termsBlock: {},
            buttonOnMobile: false,

        };
    },
    watch: {
        item(){
            this.searchDate = this.initialSearchDate;
            this.termsBlock = { ...this.item };
        },
    },
    methods: {

        getFreeTermByDate(date) {

            const termsBlock = this.termsBlock.free_terms.find(dateFreeTerms => this.$moment(dateFreeTerms.date).isSame(date, 'day'));
            const results = termsBlock ? termsBlock.free_terms : [];

            return results;
        },

        findNextFreeTerms(date) {

            this.app__loaderEnqueue(() => {

                return new Promise((resolve, reject) => {

                    this.app__getToken().then(token => {

                        const params = {
                            selected_facility_token: token.accessToken,
                            remote_app: token.remoteApp,
                            date,
                            doctor_id: this.item.doctor.id,
                            department_id: this.item.department.id,
                            specialty: this.selectedSpeciality && this.selectedSpeciality.name ? this.selectedSpeciality.name : '',
                            evisit: this.visitType === 'evisit',
                            visit_kind: this.visitKind,
                        };

                        if (this.app__isMobile()) params.limit = 1;

                        this.$http.get('visits/sync_search_free_terms/', {
                            headers: {
                                'Content-Type': 'Application/json',
                            },
                            params,
                        }).then(response => {

                            this.searchDate = date;
                            [this.termsBlock] = response.data;
                            resolve();

                        }).catch(error => {
                            reject(error);
                        });

                    });

                });

            });
        },

        chooseTerm(newVisit){

            const data = {
                newVisit,
                doctor: this.item.doctor,
                department: this.item.department,
                app: this.item.app,
                evisit: this.visitType === 'evisit',
                visit_kind: this.visitKind,
                prePayment: this.item.force_visit_prepayment,
                prePaymentInfo: this.item.visit_prepayment_info,
            };

            this.$emit('select', data);
        },

        resetSearch(){
            if (!this.initialSearchDate) return;
            this.searchDate = this.initialSearchDate;
            this.findNextFreeTerms(this.searchDate);
        },

        getSearchAddress(department){

            return {
                street: `${department.street} ${department.house_number}`,
                city: department.city,
                postalcode: department.postal_code,
                country: 'Polska',
            };

        },

    },
    computed: {

        ...mapState('patient', ['userData', 'selectedProfile']),
        ...mapState('global', ['remoteAppData', 'selectedTerm', 'selectedSpeciality']),

        visitDates(){

            const results = [];
            let currentDate = this.$moment(this.searchDate);

            const mobile = this.app__isMobile();
            const counter = mobile ? 1 : this.$config.RESTRICTIONS.FREE_TERMS_RANGE;

            for (let i = 0; i < counter; i += 1) {
                results.push(currentDate);
                currentDate = this.$moment(currentDate).add(1, 'days');
            }

            return results;

        },

        prevWeekFirstDay(){

            const date = this.$moment(this.searchDate);
            const now = this.$moment();

            if (date.isSame(now, 'day')) return null;

            const mobile = this.app__isMobile();
            const counter = mobile ? 1 : this.$config.RESTRICTIONS.FREE_TERMS_RANGE;

            date.subtract(counter, 'days');

            if (date.isBefore(now, 'day')) return now.format('YYYY-MM-DD');
            return date.format('YYYY-MM-DD');

        },

        nextWeekFirstDay(){
            const mobile = this.app__isMobile();
            const counter = mobile ? 1 : this.$config.RESTRICTIONS.FREE_TERMS_RANGE;
            return this.$moment(this.searchDate).add(counter, 'days').format('YYYY-MM-DD');
        },

        btnShowed() {

            const windowWidth = document.body.offsetWidth;

            if (windowWidth <= 600){

                const termsBlock = this.termsBlock.free_terms.find(dateFreeTerms => this.$moment(dateFreeTerms.date).isSame(this.searchDate, 'day'));
                const results = termsBlock ? termsBlock.free_terms : [];

                if (results.length > 5) return true;
                return false;

            }

            const count = this.termsBlock.free_terms.filter(day => day.free_terms.length > 5).length;
            return count;

        },

        nextSearchRangeDate() {
            return this.$moment(this.searchDate).add(this.$config.RESTRICTIONS.FIRST_FREE_TERM_FOR_DOCTOR_RANGE, 'days').format('YYYY-MM-DD');
        },

        isPrivate(){
            return this.visitKind === 'Prywatna';
        },

        isNFZ(){
            return this.visitKind === 'NFZ';
        },
    },
    created(){

        this.searchDate = this.initialSearchDate;
        this.termsBlock = { ...this.item };

    },

};

</script>

<style lang="scss" src="./visitCalendar.scss" />
